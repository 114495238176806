<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Gestión de Bodegas</h3>
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-0">
                <label>Proveedor *</label>
                <fieldset>
                  <select
                      name="proveedor"
                      class="form-control mb-2 mb-md-0"
                      v-model="proveedor"
                      @change="consultarXProveedor"
                  >
                    <option value="" disabled>Seleccione un proveedor</option>
                    <option
                        v-for="proveedor in proveedores"
                        :key="proveedor.id"
                        :value="proveedor.id"
                    >
                      {{ proveedor.nombreProveedor }}
                    </option>
                  </select>
                </fieldset>
              </div>
            </div>
            <div class="col-md-6">
              <button @click="agregar" class="btn btn-primary btn-general float-right">
                <span class="icon-plus"></span> Agregar
              </button>
            </div>
          </div>
        </div>

        <TablaFiltro :data="bodegas" :columns="columnas" @edit="editar"
                     @delete="eliminar" show-modal @modal="modal"/>

        <ModalBodegaGerencia :displayModal="displayModal" @close-modal="displayModal = false" :id-bodega="idBodega" />

      </div>
    </div>
  </div>

</template>
<script>
import TablaFiltro from "../../../../components/TablaFiltro.vue";
import ModalBodegaGerencia from "@/components/ModalBodegaGerencia.vue";

export default {
  name: "GestionBodegas",
  components: {
    TablaFiltro,ModalBodegaGerencia
  },
  data() {
    return {
      proveedor: "",
      searchQuery: '',
      columnas: [
        {campo: "tipoBodega", tipo: "String", nombre: "Tipo Bodega"},
        {campo: "nombre", tipo: "Text", nombre: "Nombre"},
        {campo: "direccion", tipo: "Text", nombre: "Dirección"},
        {campo: "latitud", tipo: "Numero", nombre: "Latitud"},
        {campo: "longitud", tipo: "Numero", nombre: "Longitud"},
        {campo: "tipoSolicitudes", tipo: "Arreglo", nombre: "Tipo Solicitud"}
      ],
      displayModal: false,
      idBodega : 0
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
  },
  async mounted() {
    await this.consultarProveedores();
    await this.consultarXProveedor();

  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },

    async consultarProveedores() {
      try {
        await this.$store.dispatch("proveedores/consultar");
        if (this.proveedorSeleccionado) {
          this.proveedor = this.proveedorSeleccionado.id;
        } else {
          this.proveedor = this.proveedores[0].id;
        }
      } catch (error) {
        alert("No se pudo realizar la consulta de proveedores");
      }
    },
    async consultarXProveedor() {
      this.loaderSpinner();
      try {
        if (this.proveedor) {
          await this.$store.dispatch("bodegas/consultarXProveedor", {proveedor: this.proveedor});
          const proveedorSeleccionado = this.proveedores.find(item => item.id === this.proveedor);
          await this.$store.commit("proveedores/setProveedorSeleccionado", proveedorSeleccionado);
        }
      } catch (error) {
        alert("No se pudo realizar la consulta de las bodegas");
      } finally {
        this.loader.hide();
      }
    },
    async agregar() {
      await this.$store.commit("bodegas/setBodegaSeleccionada", null);
      this.$router.push({name: "BodegaForm"});
    },
    async editar(bodega) {
      await this.$store.commit("bodegas/setBodegaSeleccionada", bodega);
      this.$router.push({name: "BodegaForm"});
    },
    async eliminar(id) {
      this.$swal({
        text: "¿Está seguro de eliminar la información?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loaderSpinner();
          try {
            await this.$store.dispatch("bodegas/eliminar", {id: id});
            this.$swal({
              text: "Registro eliminado con éxito.",
              icon: "success",
            });
          } catch (error) {
            this.$swal("", error.message, "error");
          } finally {
            this.loader.hide();
          }
        }
      });
    },
    async modal(row) {
      this.idBodega = row.id;
      this.displayModal = true;
    },
    cerrarModalArchivo() {
      this.displayModal = false;
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    bodegas() {
      return this.$store.getters["bodegas/bodegas"]
          ? this.$store.getters["bodegas/bodegas"]
          : [];
    },
    proveedores() {
      return this.$store.getters["proveedores/proveedores"]
          ? this.$store.getters["proveedores/proveedores"]
          : [];
    },
    proveedorSeleccionado() {
      return this.$store.getters["proveedores/proveedorSeleccionado"]
          ? this.$store.getters["proveedores/proveedorSeleccionado"]
          : null;
    }
  },
};
</script>
<style></style>
